export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      bundleDeactivationAllowedAt
      accountPremiumStatus
      accountCanceledNotes
      accountCanceledReason
      stripeBillingEmail
      timeZone
      companyName
      group
      originationNumber
      trialExpDate
      firstInterestDateTime
      firstStartedTrialDateTime
      firstConvertedToPaidDateTime
      firstChurnedDateTime
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      lastPaidPositiveInvoiceLineItemDateTime
      totalNumberOfMonthsPaidByTenant
      lifetimePaymentTotal
      averageMonthlyInvoiceTotal
      shortCode
      growSurfParticipantId
      growSurfParticipantEmail
      growSurfParticipantFullName
      growSurfReferredByEmailBackup
      growSurfReferredByFullNameBackup
      growSurfParticipantReferralComplete
      nameLog
      notes
      stripeCustomerId
      customerSubType
      updatedAt
      messageServiceProvider
      accountType
      customerType
      customerSubType
      accountCountry
      isTemporaryAccount
      isTestingAccount
      allowParentData
      zohoCrmAccountRecordId
      parentAccountId
      parentAccount {
        id
        companyName
        zohoCrmAccountRecordId
      }
      premiumStatusHistory {
        items {
          id
          accountPremiumStatus
          changeNotes
          createdAt
        }
      }
      invoices {
        items {
          id
          month
          year
          createdAt
          averageActiveDriverCount
          invoiceTotal
          status
        }
        nextToken
      }
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          phone
          email
          role
          isOwner
          isCreator
          updatedAt
          createdAt
          permissionLogin
          lastLogin
        }
        nextToken
      }
      customerStatus
    }
  }
`;

export const getTenantAccountData = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      accountType
    }
  }
`;

export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        discountFixed
        discountPercent
        trialExpDate
        numberOfSeats
        originationNumber
        shortCode
        group
        automatedCoachingSendTime
        originationNumber
        messageServiceProvider
        bundleDeactivationAllowedAt
        accountPremiumStatus
        stripeCustomerId
        customerSubType
        users {
          items {
            id
            email
            phone
          }
        }
        customerStatus
      }
      nextToken
    }
  }
`;

export const searchTenants =/* GraphQL */  `
  query searchTenants(
    $from: Int
    $filter: SearchableTenantFilterInput
    $limit: Int
    $nextToken: String
    $sort: SearchableTenantSortInput
  ) {
  searchTenants(
    from: $from
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sort: $sort
  ) {
    items {
      id
      shortCode
      companyName
      group
      accountPremiumStatus
      trialExpDate
      messageServiceProvider
      customerStatus
      originationNumber
    }
    nextToken
  }
}
`;

export const countTenants =/* GraphQL */  `
  query searchTenants(
    $filter: SearchableTenantFilterInput
    $limit: Int
    $nextToken: String
    $sort: SearchableTenantSortInput
  ) {
  searchTenants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sort: $sort
  ) {
    nextToken
    total
  }
}
`;


export const documentsByType =/* GraphQL */  `
  query DocumentByType($type: String) {
  documentsByType(type: $type) {
    items {
      id
      notes
      group
    }
  }
}
`

export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      invoiceLineItems {
        items {
          id
          date
          activeStaff
          standardCostExt
          bundleCostExt
          performanceCostExt
          rosteringCostExt
          staffCostExt
          isTrial
          vehiclesCostExt
          messagingCostExt
          createdAt
        }
        nextToken
      }
     
    }
  }
`;


export const listUserIds = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoSub
        group
      }
      nextToken
    }
  }
`;

export const valueListsByKey = /* GraphQL */`
  query ValueListsByKey(
    $key: String
    $sortDirection: ModelSortDirection
    $filter: ModelValueListFilterInput
    $limit: Int
    $nextToken: String
  ){
    valueListsByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ){
      items{
        id
        key
        tenant{
          id
          companyName
          group
        }
        items{
          items{
            id
            value
            hidden
            deleted
            group
            custom
          }
        }
      }
    }
  }
`;

export const vehiclesByStatus = /* GraphQL */ `
  query VehiclesByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        status
      }
      nextToken
    }
  }
`;

export const staffsByStatus = /* GraphQL */ `
query StaffsByStatus(
  $status: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByStatus(
    status: $status
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      status
    }
    nextToken
  }
}
`;

export const devicesByStatus = /* GraphQL */ `
  query DevicesByStatus(
    $status: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByStatus(
      status: $status
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        status
      }
      nextToken
    }
  }
`;

export const listStaffs = /* GraphQL */ `
  query ListStaffs(
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        email
        transporterId
      }
      nextToken
    }
  }
`;

export const listAccidents = /* GraphQL */ `
  query ListAccidents(
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        staffId
        vehicleId
        vehicleHistoryType
        accidentDate
        staff {
          id
        }
        verifiedBy {
          id
        }
        vehicle {
          id
        }
      }
      nextToken
    }
  }
`;

export const listPendingMessages = /* GraphQL */ `
  query ListPendingMessages(
    $filter: ModelPendingMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        staffId
        staff {
          id
        }
      }
      nextToken
    }
  }
`;

export const updateTenantCoaching = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      group
    }
  }
`;


export const updateTenantMessageService = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      group
      messageServiceProvider
      updatedAt
    }
  }
`;

export const updateTenantPlans = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      group
      accountPremiumStatus
      customerStatus
      updatedAt
    }
  }
`;

export const tenantsByAccountType = /* GraphQL */ `
  query TenantsByAccountType(
    $accountType: TenantAccountType
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByAccountType(
      accountType: $accountType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
      }
      nextToken
    }
  }
`;

export const updateTenantAccountType = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      accountType
      allowParentData
      parentAccountId
      updatedAt
    }
  }
`;
