import { API, graphqlOperation } from 'aws-amplify';
import store from '@/store/index.js'
import { staffsByGroup } from '@/views/Staff/queries'
import { devicesByGroup } from '@/store/queries.js'
import { mixinMethods } from "@/main";
import { usersByPhone } from '@/views/Settings/Users/query'
import { validatePhoneNumberFormatSingle, cleanPhoneNumber } from '@/utilities/phoneNumberFormat'
import {i18n} from '@/main'
export default {

    async validatePhoneNumberDA(rule, value, callback)
    {
      if (!value) {
        callback();
        return
      }
      const validator = await validatePhoneNumberFormatSingle(value);
      if (!validator) callback(new Error('Please enter a valid US mobile number.'));
      const associateList = store.getters['subscriptionStore/getAssociatesList'] // Get the associate list from the store
      value = cleanPhoneNumber(value, 'US')
      const existingAssociate = associateList.find(da => da.phone == value)
      if (!!existingAssociate && !(rule.daId === existingAssociate?.id)) callback(new Error('The phone number entered already exists.'))
      else callback();
  },

    async validatePhoneNumberDevice(rule, value, callback)
    {
      if (!value) {
        callback();
        return
      }
      const validator = await validatePhoneNumberFormatSingle(value);
      if (!validator) callback(new Error('Please enter a valid US mobile number.'));
      callback();
    },

    async validatePhoneNumberUser(rule, value, callback)
    {
      if (!value) {
        callback()
      } else {
        const validator = await validatePhoneNumberFormatSingle(value);
        if (!validator) {
          callback(new Error('Please enter a valid US mobile number.'))
        }
        else {
            value = cleanPhoneNumber(value, 'US')
            var input = { phone: value }
            var existingPhone = await API.graphql(graphqlOperation(usersByPhone, input))
            var isPhoneInSameTennant = false;
            var userTennats = existingPhone.data.usersByPhone.items.map(da => { return da.group })
            for(var user of userTennats) {
              isPhoneInSameTennant = user.includes(rule.params.group)
              if(isPhoneInSameTennant) break;
            }
            var daIds = existingPhone.data.usersByPhone.items.map(da => { return da.id })
            if(rule.params.daId) var isCurrentDa = daIds.includes(rule.params.daId)
            if(daIds.length && !isCurrentDa && isPhoneInSameTennant) {
              callback(new Error('The phone number entered already exists.'))
            } else {
              callback();
            }
        }
      }
    },
    
    async uniqueVIN(rule, vin, callback){
      if(vin) {
        try {
          const vehicles = store.getters['subscriptionStore/getVehicles']
          const existingVehicle = vehicles.find(vehicle => vehicle.vin?.toLowerCase() == vin.toLowerCase())
          if ((rule.daId != existingVehicle?.id) && !!existingVehicle) callback(new Error(`A VIN already exists with this vehicle.`))
        } catch (error) {
          console.error('error', error);
        }
      }
      callback()
    },  

    async uniqueEmailDA(rule, email, callback){
      let associateList = store.getters['subscriptionStore/getAssociatesList'] // Get the associate list from the store
      // if data is not loaded in the store yet, get the data from the graphql
      if (!associateList.length) {
        const input = { group: store.state.userInfo.tenant.group }
        associateList = await mixinMethods.gLoadListAll(staffsByGroup, input, 'staffsByGroup');
      }
      if(email){
        const existingAssociate = associateList.find(da => da.email?.toLowerCase() == email.toLowerCase())
        if (!(rule.daId === existingAssociate?.id) && !!existingAssociate) callback(new Error(`${i18n.t('label.An_associate')} already exists with this email.`))
      }
      else if(rule.required && !email) callback(new Error("Please input an email address"))
      callback()
    },  

    async uniqueNameDevice(rule, name, callback) {
      let deviceList = store.getters['subscriptionStore/getDevices']; // Get the device list from the store
    
      // if data is not loaded in the store yet, get the data from the GraphQL
      if (!deviceList.length) {
        const input = { group: store.state.userInfo.tenant.group };
        deviceList = await mixinMethods.gLoadListAll(devicesByGroup, input, 'devicesByGroup');
      }
    
      if (name) {
        // Find a device where the name matches the provided name, excluding the current device being validated
        const existingDevice = deviceList.find(device => device.deviceName?.toLowerCase() === name.toLowerCase() && device.id !== rule.deviceId);
    
        if (existingDevice) {
          callback(new Error(`Device already exists with this name.`));
          return;
        }
      } else if (rule.required && !name) {
        callback(new Error("Please input device name"));
        return;
      }
      callback();
  },

    async uniquePhoneNumberDevice(rule, phoneNumber, callback) {
      let deviceList = store.getters['subscriptionStore/getDevices']; 
      if (!deviceList.length) {
        const input = { group: store.state.userInfo.tenant.group };
        deviceList = await mixinMethods.gLoadListAll(devicesByGroup, input, 'devicesByGroup');
      }
      phoneNumber = cleanPhoneNumber(phoneNumber, 'US')
      if (phoneNumber) {
        const existingDevice = deviceList.find(device => device.phoneNumber === phoneNumber && device.id !== rule.deviceId);
    
        if (existingDevice) {
          callback(new Error(`Device already exists with this Phone Number.`));
          return;
        }
      } else if (rule.required && !phoneNumber) {
        callback(new Error("Please input device Phone Number"));
        return;
      }
      callback();
    },

    async validateLicensePlateAndState(rule, value, callback) {
      if (rule.fieldName == 'license') {
        if( (rule.formField.licensePlate == '' || rule.formField.licensePlate == null ) && rule.formField.state!='') callback(new Error(`License Plate is required when you provide a state.`));
      } else if (rule.fieldName == 'state') {
        if( (rule.formField.state == '' || rule.formField.state == null) && rule.formField.licensePlate!='') callback(new Error(`License State is required if you add a License Plate.`));
      }
      if(rule.formField.licensePlate == '' && rule.formField.state == ''){
        callback();
      } else {
        try {
          let currentCombination = (rule.formField.licensePlate + rule.formField.state).trim();
          const vehicles = store.getters['subscriptionStore/getVehicles']

          let currentValue = ''
          let isValueChanged = false;
          let isDuplicated = false;
          for(let vehicle of vehicles) {
            let combination = (vehicle.licensePlate + vehicle.state) || '';
            if(currentCombination.toLowerCase() == combination?.toLowerCase()){
              isDuplicated = true;
            }
            if(vehicle.id == rule.formField.id) {
              if (rule.fieldName == 'license') currentValue = vehicle.licensePlate;
              else if (rule.fieldName == 'state') currentValue = vehicle.state;
            }
          }
          
          if(currentValue != value) isValueChanged = true
          if(isValueChanged && isDuplicated) {
            callback(new Error('This set for License Plate and State already exists'))
          } else callback();
        } catch (error) {
          console.error('error validating license plate', error);
        }
      }
    },
    
    async uniqueTransporterIdDA(rule, transporterId, callback){
      let associateList = store.getters['subscriptionStore/getAssociatesList'] // Get the associate list from the storelet associateList = []
      // if data is not loaded in the store yet, get the data from the graphql
      if (!associateList.length) {
        const input = { group: store.state.userInfo.tenant.group }
        associateList = await mixinMethods.gLoadListAll(staffsByGroup, input, 'staffsByGroup');
      }
      if(transporterId){
        const existingAssociate = associateList.find(da => da.transporterId?.toLowerCase() == transporterId.toLowerCase())
        if (!(rule.daId === existingAssociate?.id) && !!existingAssociate) callback(new Error(`${i18n.t('label.An_associate')} already exists with this Transporter ID.`))
      }
      callback()
    },  

    /**
     * Custom validator that ensures something resembling an email is present.
     * @param {Object} rule 
     * @param {String} value 
     * @param {Function} callback 
     */
    email(rule, value, callback){
        var old_re = /\S+@\S+\.\S+/
        var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        var isEmail = re.test(value) && old_re.test(value)
        if( !isEmail || value === '' ) {
            let message = rule.message || "A valid email is required"
            callback(new Error(message))
        }
        callback()
    },

    vehicleNameFormatAndRequired(rule, value, callback){
      if(!value){
        let message = rule.message || "A vehicle name is required"
        callback(new Error(message))
      }
      /**
       * 
       *Must be between 3 and 63 characters long.
        Can consist only of letters, numbers, dots (.), and hyphens (-).
        Must begin and end with a letter or number.
        Can't begin with "xn--".
       */
      var re = /^(?!xn--)[a-zA-Z0-9]{1}[a-zA-Z0-9-.]{1,61}[a-zA-Z0-9]{1}$/
      var isValid = re.test(value);

      if(!isValid){
        let message = rule.message || "A value name is required. Use rules listed below"
        callback(new Error(message))
      }
      callback()
    },

    time(rule, value, callback){
        var re = /^((1[0-2])|(0?[0-9])):[0-5][0-9](a|p|A|P)(m|M)$/g
        if (!value) {
          callback(new Error('Invalid Time. Use format HH:MMAM/PM'));
        }
        else if (!value.match(re)) {
          callback(new Error('Invalid Time. Use format HH:MMAM/PM'));
        }
        else {
          callback();
        }
    },

    timeNotConfirmed(rule, value, callback){
      var re = /^((1[0-2])|(0?[0-9])):[0-5][0-9](a|p|A|P)(m|M)$/g
      if (!value) {
        callback();
      }
      else if (!value.match(re)) {
        callback(new Error('Invalid Time. Use format HH:MMAM/PM'));
      }
      else {
        callback();
      }
  },

    staff(rule, value, callback){
      if(!value || !value.id){
        let message = rule.message || `Please select ${i18n.t('label.an_associate')}`
        callback(new Error(message))
      }
      else {
        callback();
      }
    },

    incidentType(rule, value, callback){
      if(!value.id){
        let message = rule.message || "Please select a incidentType"
        callback(new Error(message))
      }
      else {
        callback();
      }
    },

    verifiedBy(rule, value, callback){
      if(!value.id){
        let message = rule.message || "Please select a verifiedBy"
        callback(new Error(message))
      }
      else {
        callback();
      }
    },

    user(rule, value, callback){
      if(!value.id){
        let message = rule.message || "Please select a user"
        callback(new Error(message))
      }
      else{
        callback();
      }
    },
    
    odometerType(rule, value, callback){
      if(!value){
        let message = rule.message || "Please select a type"
        callback(new Error(message))
      }
      else{
        callback();
      }
    },

    delete(rule, value, callback){
      if(value) value = value.toLowerCase().trim()
      if( value != 'delete' ) {
          let message = rule.message || "Please enter the correct phrase to confirm"
          callback(new Error(message))
      }
      callback()
  },


  vehicle(rule, value, callback){
    if(!value || !value.id){
      let message = rule.message || "Please select a vehicle"
      callback(new Error(message))
    }
    else {
      callback();
    }
  },

  assigneeCheck(rule, value, callback){
    if(value.id == null || value.id == undefined || value.id == ""){
      let message = rule.message || "Please select an assignee"
      callback(new Error(message))
    }
    else {
      callback();
    }
  },

  maintenanceReminder(rule, value, callback){
    if(!rule.dueByDate && !rule.dueByMileage){
      let message = rule.message || "Date or mileage must be filled in"
      callback(new Error(message))
    }
    else{
      callback();
    }
  },

  maintenanceServices(rule, value, callback){
    if(!value || value.length == 0){
      let message = rule.message || "A service must be selected"
      callback(new Error(message))
    }
    else{
      callback();
    }
  },

  maintenanceVehicles(rule, value, callback){
    if(!value || value.length == 0){
      let message = rule.message || "A Vehicle must be selected"
      callback(new Error(message))
    }
    else{
      callback();
    }
  }
}
