<template>
  <div>
    <div class="flex items-center justify-between">
      <h1>Telnyx</h1>
      <div class="flex space-x-2">
        <el-input
          v-model="search"
          size="small"
          prefix-icon="uil uil-search"
          clearable
          class="flex-1"
          placeholder="Filter this list by any column..."
        />
      </div>
    </div>

    <div  class="table-telnyx-numbers" v-loading="loading">
      <div class="grid grid-flow-row grid-cols-12 w-full py-3 gap-4">
        <div class="col-span-12 md:col-span-6 lg:col-span-4">
          <div>Available numbers: {{ availableCounter }}</div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4">
          <div>Numbers in use: {{ inUseCounter }}</div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4">
          <div>Total: {{ totalCounter }}</div>
        </div>
        <div class="col-span-12 md:col-span-12 lg:col-span-12">
          <p :class="classColorConflict">Conflict numbers: {{ conflictNumbers.length }}</p>
          <ul class="list-disc list-inside mt-2">
            <li v-for="number in conflictNumbers" class="text-red-500">
              {{ number.phoneNumber }} ({{ number.enviroment }})
            </li>
          </ul>
        </div>
        <div class="col-span-12 md:col-span-12 lg:col-span-12">
          <p :class="deprecatedNumbersClassColorConflict">Corrupted numbers assigned to deprecated groups: {{ corruptedTelnyxList.length }}</p>
          <ul class="list-disc list-inside mt-2">
            <li v-for="number in corruptedTelnyxList" class="text-red-500">
              {{ number.phoneNumber }} (deprecated group: {{ number.group }}) ({{ number.enviroment }})
            </li>
          </ul>
        </div>
      </div>

      <el-table
        ref="table"
        class="data-table"
        empty-text="No Data"
        :data="filterArray(tableData, search)"
        header-cell-class-name="table-header-cell"
        :default-sort="{ prop: 'companyName', order: 'ascending' }"
      >
        <el-table-column sortable label="Number" prop="phoneNumber">
        </el-table-column>

        <el-table-column label="Tenant" sortable prop="companyName">
          <template slot-scope="scope">
            <div v-if="(scope.row.companyName && scope.row.companyName.toLowerCase().includes('deprecated'))">
              <div class="font-bold bg-opacity-10 max-w-full flex-initial text-red-600 ">
                {{ scope.row.companyName }}
              </div>
            </div>
            <div v-else-if="(scope.row.companyName)" >
              {{ scope.row.companyName }}
            </div>
            <div v-else>
              {{ "-" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Trial Expiration" sortable prop="trialExpDate">
        </el-table-column>

        <el-table-column label="Enviroment" sortable prop="enviroment">
          <template slot-scope="scope">
            <CellToolTip :value="scope.row.enviroment">
              <div slot-scope="{textValue}" class="flex">
                <span class="font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial" :class="setClassStatus(scope.row, true)">
                  {{textValue.toUpperCase()}}
                </span>
              </div>
            </CellToolTip>
          </template>
        </el-table-column>
        <el-table-column align="center" width="40" fixed="right" class-name="custom-menu-icon">
          <template v-slot:header>
              <el-dropdown data-cy="dropdown-da-header-table" class="mt-1" @command="handleCommand" trigger="click" :hide-on-click="true">
                  <ContextMenuHeaderIcon class="mr-2 context-menu-header-hover"/>
                  <el-dropdown-menu slot="dropdown" class="action-dropdown-menu">
                      <el-dropdown-item :command="{action: 'exportCsv'}">Export All {{ totalCounter }} Records</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <br/>
    <div class="grid grid-flow-row grid-cols-12 w-full py-2 gap-4">
      <div class="col-span-12 md:col-span-12 lg:col-span-12">
          <p :class="trialClassColorConflict">Trial Tenants without a Expired Trial Date: {{ trialWithoutExpDate.length }}</p>
          <ul class="list-disc list-inside mt-2">
            <li v-for="tenant in trialWithoutExpDate" class="text-red-500">
              Company Name: "{{ tenant.companyName }}" (group: {{ tenant.group }}) (trialExpDate: {{ tenant.trialExpDate }})
            </li>
          </ul>
        </div>
    </div>
    <div class="grid grid-flow-row grid-cols-12 w-full py-2 gap-4">
      <div class="col-span-12 md:col-span-12 lg:col-span-12">
          <p :class="statusClassColorConflict">Tenants without Customer Status: {{ unknowTenants.length }}</p>
          <ul class="list-disc list-inside mt-2">
            <li v-for="tenant in unknowTenants" class="text-red-500">
              Company Name: "{{ tenant.companyName }}" (group: {{ tenant.group }})
            </li>
          </ul>
        </div>
    </div>
    <TableCard class="mt-5 rounded-lg">
      <template v-slot:table-filters>
        <container-filter inTable>
          <template v-slot:title>
            <span>MESSAGE COUNTER</span>
          </template>
          <template v-slot:filter-count>
            <counter-filter :counter="getResumeFilters" />
          </template>
          <template v-slot:filters>
            <MultiSelectFilter
              type="status"
              title="Customer Status"
              filterAllTitle="Customer Statuses"
              :options="filterStatus"
              :selected="activeFilters"
              @update-filters="updateFilters"
            />  
            <MultiSelectFilter
              type="subtype"
              title="Customer Sub-Type"
              filterAllTitle="Customer Sub-Types"
              :options="filterSubType"
              :selected="filterSubTypeChecked"
              @update-filters="updateFilters"
            />
            <month-picker-filter
              type="month"
              placeholder="Pick a month"
              :default="month"
              param="month"
              @update-filters="updatePicker"
              :options="pickerOptions"
            />
          </template>
      </container-filter>
    </template>
    <template v-slot:table-searchbox>
      <search-box inTable @search-data="searchData"></search-box>
    </template>
    <custom-table
      inTable
      ref="table"
      v-loading="loadingCounter"
      :columns="cols"
      :data="filteredData"
      emptyText="No Data"
      footerTable="total Tenants" 
      :totalRecords="allRecordsCount"  
      :totalFilteredRecords="filteredData.length"
      :optionsRows="optionsContextRow"
      @click-menu="handleCommand"
      class="telnyx-messages-class with-fixed"
      withTableMenu
      @export-data="onExportData"
      showDefaultExportOptions
    >
      <template #companyName="row">
        <span class="pl-2 inline-block">{{ row.companyName }}</span>
      </template>

      <template #status="row">
        <CellToolTip :value="row.status">
          <div slot-scope="{textValue}" class="flex">
            <span class="font-bold rounded-full bg-opacity-10 px-3 text-xs  max-w-full flex-initial" :class="setClassStatus(row)">
              {{textValue}}
            </span>
          </div>
        </CellToolTip>
      </template>
    </custom-table>
  </TableCard>
    <!--Export Dialog-->
    <el-dialog
        :title="`Export ${exportTitle}`"
        :show-close="true"
        :before-close="handleCloseMessage"
        :center="true"
        :visible.sync="showExportDialog"
        width="300px"
        :custom-class="'customized'">
        <ExportDialog
          :modal="true"
          exportField="message"
          exportFields="messages"
          :number="exportTitle == 'Numbers' ? totalCounter : getResultTotal"
          :statusChecked="getStatusChecked()"
          @export-action="handleExportData"
          @close-dialog="handleCloseMessage"
          :hideFilters="!isFiltered">
        </ExportDialog>
    </el-dialog>
  </div>
</template>

<script>
import { listTenants, messagesByGroup } from './queries'
import { listTelnyxs } from '@/graphql/queries'
import { setTenantStatus } from '../Tenants/functions'
import { getAllTelnyxTenantEnviroment } from './utils'

import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon'
import { API } from 'aws-amplify';
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import ExportDialog from '@/components/ExportDialog.vue';
import SearchBox from "@/components/TableCard/SearchBox";
import CounterFilter from "@/components/CounterFilters";
import MonthPickerFilter from '@/components/MonthPickerFilter';
import CellToolTip from '@/components/CellToolTip';
import MultiSelectFilter from "@/components/MultiSelectFilter";
import { multipleDefaultFilter } from '@/components/filtersFunctions';
import { CUSTOMER_SUB_TYPE_XL, CUSTOMER_SUBTYPE } from '@/views/System/Tenants/constants'

export default {
  components: { 
    TableCard,
    CustomTable,
    ContainerFilter,
    ExportDialog,
    SearchBox,
    CounterFilter,
    MonthPickerFilter,
    CellToolTip,
    MultiSelectFilter,
    ContextMenuHeaderIcon
  },
  data() {
    return {
      conflictNumbers: [],
      corruptedTelnyxList: [],
      trialWithoutExpDate: [],
      unknowTenants: [],
      loading: false,
      tableData: [],
      allTenants: [],
      search: '',
      isFiltered: false,
      showExportDialog: false,
      exportTitle: '',
      nextToken: null,
      availableCounter: 0,
      inUseCounter: 0,
      totalCounter: 0,
      filterStatus: [
        'Active - Bundle',
        'Active - Premium',
        'Active - Standard',
        'Churned',
        'Lapsed Trial',
        'Trial',
        'Unknown'
      ],
      filterSubType: CUSTOMER_SUBTYPE,
      month: null,
      counterMessageTable : [],
      originalCounterMessageTable: [],
      searchMessageCounter: '',
      allRecordsCount: 0,
      loadingCounter: false,
      optionsContextRow: [ 
        { label: "View DSP", action: 'view', divided: false }
      ],
      cols: [
        { name: "Company Name", col: "companyName", fixed: true, width: "150", sortable: true, sortMethod: this.sortCompanyName },
        { name: "Outbound Messages", col: "outbound", fixed: false, width: "150", sortable: true, sortMethod: this.sortOutbound },
        { name: "Inbound Messages", col: "inbound", fixed: false, width: "140", sortable: true, sortMethod: this.sortInbound },
        { name: "Total Messages", col: "total", fixed: false, width: "140", sortable: true, sortMethod: this.sortTotal },
        { name: "Customer Status", col: "status", fixed:false, width: "140", sortable: true, sortMethod: this.sortStatus}
      ],
      pickerOptions: {
				disabledDate(time) {
					return time > new Date();
				}
      },
    }
  },
  created() {
    this.loadAllTenants()
  },
  mounted() {
    if (sessionStorage.getItem("filtersTenantTelnyx") === null) {
      this.month = new Date();
    } else {
      const filters = JSON.parse(sessionStorage.getItem("filtersTenantTelnyx"));
      this.month = new Date( filters.month );
    }
    this.loadData()
  },

  computed: {
    activeFilters: {
      get(){
        const type = "status"
        const query = this.$route.query[type]
        const filtersList = this.filterStatus
        let defaultFilter = ['Active - Bundle', 'Active - Premium', 'Active - Standard']
        if (sessionStorage.getItem("filtersTenantTelnyx") != null){
          const filters = JSON.parse(sessionStorage.getItem("filtersTenantTelnyx"));
          defaultFilter = filters.status;
        }
        
        return multipleDefaultFilter(query, defaultFilter, filtersList)
      },
      set(items){},
    },

    filterSubTypeChecked: {
      get() {
        return this.getFilter('subtype', CUSTOMER_SUBTYPE, this.filterSubType);
      },
      set(items) {
        this.setFilter('subtype', items);
      }
    },

    filteredData() {
      return this.filterArray(this.counterMessageTable, this.searchMessageCounter);
    },

    getResumeFilters() {
      const countStatus = this.activeFilters.length === this.filterStatus.length ? 0 : 1;
      const counsSubType = this.filterSubTypeChecked.length === this.filterSubType.length ? 0 : 1;
      const countDate = this.month !== null ? 1 : 0;
      return countStatus + counsSubType + countDate;
    },
    classColorConflict() {
      return this.conflictNumbers.length > 0 ? 'text-red-500 font-semibold' : 'text-black';
    },
    deprecatedNumbersClassColorConflict() {
      return this.corruptedTelnyxList.length > 0 ? 'text-red-500 font-semibold' : 'text-black';
    },
    trialClassColorConflict() {
      return this.trialWithoutExpDate.length > 0 ? 'text-red-500 font-semibold' : 'text-black';
    },
    statusClassColorConflict() {
      return this.unknowTenants.length > 0 ? 'text-red-500 font-semibold' : 'text-black';
    },
    getResultTotal() {
      return this.filteredData.length || this.allRecordsCount
    },
  },

  methods: {
    findRepeatedPhoneNumber(objArray) {

      const phoneMap = {};
      const objNumberMap = []

      for (const obj of objArray) {
        const phoneNumber = obj.phoneNumber;
        const enviroment = obj.enviroment;

        if (phoneMap[phoneNumber]) {
          phoneMap[phoneNumber].push(enviroment);
          const uniqueEnviroment = [...new Set(phoneMap[phoneNumber])]
          const item = {phoneNumber,enviroment:uniqueEnviroment.join(', ')}
          objNumberMap.push(item)
        } else {
          phoneMap[phoneNumber] = [enviroment];
        }
      }

      const uniqueArray = objNumberMap.reverse().filter((obj, index, self) => {
        const nextIndex = self.findIndex(item =>
          item.phoneNumber === obj.phoneNumber
        );
        return nextIndex === index;
      });

      return uniqueArray;
    },

    getAvailableCounter: function (list) {
      return list.filter((item) => item.group == 'null').length
    },

    getInUseCounter: function (list) {
      return list.filter((item) => item.group != 'null').length
    },

    getTrialExpirationDate: function (tenant) {
      if (!tenant.accountPremiumStatus.includes('trial')) {
        return ''
      }
      return this.$moment(tenant.trialExpDate).format('YYYY-MM-DD')
    },

    loadData: async function () {
      this.loading = true;
      this.loadingCounter = true;
      const current = this.$moment(this.month).format('YYYY-MM') + '-01';
      const next = this.$moment(this.month).add(1,'month').format('YYYY-MM') + '-01';

      try {
        const {resultTelnyx,resultTenant} = await getAllTelnyxTenantEnviroment()
        const telnyx = resultTelnyx;

        // Proccess Tenant List
        this.availableCounter = this.getAvailableCounter(telnyx);
        this.inUseCounter = this.getInUseCounter(telnyx);
        this.totalCounter = telnyx.length;
        this.tableData = this.parseTable(telnyx,resultTenant);
        this.loading = false

        // Proccess Counter Messages

        this.counterMessageTable = await this.countMessages(current, next)
        this.allRecordsCount = this.counterMessageTable.filter((message) => this.filterStatus.includes(message.status)).length        
        this.originalCounterMessageTable = [ ...this.counterMessageTable ]
        this.filterTenantsbyStatus();
        this.loadingCounter = false;
        
      } catch (e) {
        this.loading = false;
        this.loadingCounter = false;
        this.displayUserError(e)
      }
    },

    parseTable: function (telnyx, tenants) {
      let table = []
      for (const telnyxItem of telnyx) {
        telnyxItem.companyName = ''
        if (telnyxItem.group != 'null') {
          let tenant = {}
          tenant = tenants.find(
            (tenant) => tenant.group == telnyxItem.group
          )
          if (!tenant) {
            telnyxItem.companyName = "CORRUPTED DATA, deprecated group = " + telnyxItem.group 
            telnyxItem.trialExpDate = ""
            this.corruptedTelnyxList.push(telnyxItem)
          } else {
            telnyxItem.companyName = tenant.companyName
            telnyxItem.trialExpDate = this.getTrialExpirationDate(tenant)
          }
        }

          table = [...table, telnyxItem]
      }
      
      this.conflictNumbers = this.findRepeatedPhoneNumber(table)
      return table
    },

    async loadAllTenants(){
      this.allTenants = await this.gLoadListAll(listTenants, {}, 'listTenants');
      for (const tenant of this.allTenants){
          tenant.status = setTenantStatus(tenant.accountPremiumStatus, tenant.trialExpDate)
          if (tenant.status === 'Trial' && !tenant.trialExpDate) this.trialWithoutExpDate.push(tenant)
          if (tenant.status === 'Unknown') this.unknowTenants.push(tenant)
        };
      return
    },

    async countMessages (start, end) {
      const table = [];
      for(const tenant of this.allTenants) {
        const messages = await this.getMessages(start, end, tenant.group)
        const obj = { ...tenant }
        let inbound = 0
        let outbound = 0
        let total = 0
        if (!messages || messages.length === 0) {
          obj['inbound'] = 0
          obj['outbound'] = 0
          obj['total'] = 0
        } else {
          for(const message of  messages) {
            if(message.channelType.includes('SMS')) {
              inbound += 1
            } else {
              outbound += 1;
            }
          }
          total = inbound + outbound
          obj['inbound'] = inbound
          obj['outbound'] = outbound
          obj['total'] = total
        }
        table.push(obj)
      }
      return table;
    },

    async getMessages (start, end, group) {
      const input = {
        group,
        limit: 100,
        nextToken: null,
        createdAt: {
          between: [start, end]
        }
      }
      const messages = await this.gLoadListAll( messagesByGroup, input, 'messagesByGroup' )
      return messages
    },

    handleCommand: async function (payload) {
      let row = payload.row;
      let action = payload.action;
      if (action == 'view') {
        this.$router.push({ name: 'SystemTenantDetail', params: { id: row.id } });
      } else if (action === 'exportCsv') {
        this.exportTitle = "Numbers"
        this.onExportData('numbers');
      }
    },

    async handleExportNumbers() {
      this.displayUserNotification({
        title: "Export",
        type: "info",
        message: `Exported CSV file is being generated. Notification will be sent with download link once ready.`,
      });
      let body = {
        type: 'custom',
        owner: this.$store.state.userInfo.cognitoSub,
        group: this.$store.state.userInfo.tenant.group,
        payload: this.tableData,
        isFormattingRequired: true,
        report: 'telnyx',
        titleNotification: 'Telnyx'
      }

      let req = {
        body: body,
      }

      try {
        await API.post('csvDataExport', '/data-export', req);
      } catch (e) {
        this.displayUserError(e)
      }
    },

    handleExportData() {
      if(this.exportTitle == 'Numbers') this.handleExportNumbers();
      else this.handleExportDataMessage();
      this.showExportDialog = false;
    },
    async handleExportDataMessage() {
      this.displayUserNotification({
        title: "Export",
        type: "info",
        message: `Exported CSV file is being generated. Notification will be sent with download link once ready.`,
      });

      let body = {
        type: 'custom',
        owner: this.$store.state.userInfo.cognitoSub,
        group: this.$store.state.userInfo.tenant.group,
        isFormattingRequired: true,
        report: 'messageCounter',
        titleNotification: 'Message Counter'
      }

      if(this.isFiltered) {
        body.filters = {
          activeFilters: this.activeFilters,
          activeDspFilters: this.filterSubTypeChecked,
          search: this.searchMessageCounter
        }
      }

      let req = {
        body: body,
      }

      try {
        // this.loadingCounter = true;
        await API.post('csvDataExport', '/data-export', req);
      } catch (e) {
        this.displayUserError(e)
      } finally {
        // this.loadingCounter = false;
      }
    },
    handleCloseMessage() {
      this.showExportDialog = false;
      this.isFiltered = false;
      this.exportTitle = ''
    },
    onExportData(type) {
      if (type != 'numbers') this.exportTitle = 'Messages'
      this.showExportDialog = true;
      this.isFiltered = type == 'filtered';
    },
    getStatusChecked() {
      const statuses = [
        {
          filter: 'Customer Status',
          values: this.activeFilters
        },
        {
          filter: 'Customer Sub-Type',
          values: this.filterSubTypeChecked
        }
      ]
      if (this.month) statuses.push({filter: 'Month', values: [this.$moment(this.month).format('YYYY-MM')]})
      return statuses
    },

    sortCompanyName(a, b) {
      return a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase());
    },
    sortOutbound(a,b) {
      return a.outbound - b.outbound;
    },
    sortInbound(a,b) {
      return a.inbound - b.inbound;
    },
    sortTotal(a,b) {
      return a.total - b.total;
    },
    sortStatus(a, b) {
      return a.status.toLowerCase().localeCompare(b.status.toLowerCase());
    },

    updateFilters(e) {
      const {type, filters } = e;
      if (type == 'status') {
        this.activeFilters = filters;
      }

      if (type == 'subtype') {
        this.filterSubTypeChecked = filters;
      }
      const options = { status: this.activeFilters, subtype: this.filterSubTypeChecked, month: this.month };
      sessionStorage.setItem( "filtersTenantTelnyx", JSON.stringify( options ) );
      this.filterTenantsbyStatus();
    },

    filterTenantsbyStatus() {
      this.counterMessageTable = this.originalCounterMessageTable.filter( tenant => this.activeFilters.includes( tenant.status ) );
      this.counterMessageTable = this.counterMessageTable.filter( tenant => {
        let customerSubType = 'ZL'
        if( tenant.customerSubType ===  CUSTOMER_SUB_TYPE_XL) {
          customerSubType = 'XL';
        }
        if ( this.filterSubTypeChecked.includes( customerSubType ) ) {
          return true;
        }
        return false;
      })
    },

    setClassStatus(row, environment) {
      if (environment == true) {
        return{ 
          'text-purple-600' : row.enviroment.toLowerCase() == 'production',
          'bg-purple-500' : row.enviroment.toLowerCase() == 'production',
          'text-orange-600'  : row.enviroment.toLowerCase() == 'test',
          'bg-orange-500' : row.enviroment.toLowerCase() == 'test',
          'text-green-600' : row.enviroment.toLowerCase() == 'devqa',
          'bg-green-500' : row.enviroment.toLowerCase() == 'devqa',
        }
      }
      return {
        'text-yellow-600' : row.status.toLowerCase() == 'trial', 
        'bg-yellow-500' : row.status.toLowerCase() == 'trial',
        'text-red-600' : row.status.toLowerCase() == 'lapsed trial' || row.status.toLowerCase() == 'churned' || row.status.toLowerCase() == 'unknown', 
        'bg-red-500' : row.status.toLowerCase() == 'lapsed trial' || row.status.toLowerCase() == 'churned' || row.status.toLowerCase() == 'unknown',
        'text-green-600' : row.status.toLowerCase().includes('active'),
        'bg-green-500' : row.status.toLowerCase().includes('active')
      }
    },

    searchData(e) {
      this.searchMessageCounter = e;
    },

    updatePicker: async function (e) {
      this.loadingCounter = true;
      const { filter } = e;
      const current = this.$moment(filter).format('YYYY-MM-DD');
      const next = this.$moment(filter).add(1,'month').format('YYYY-MM-DD');
      this.month = filter;
      this.counterMessageTable = await this.countMessages(current, next);
      this.originalCounterMessageTable = [ ...this.counterMessageTable ];
      this.filterTenantsbyStatus();
      const options = { status: this.activeFilters, subtype: this.filterSubTypeChecked, month: this.month };
      sessionStorage.setItem("filtersTenantTelnyx", JSON.stringify( options ) );
      this.loadingCounter = false;
    },

    getFilter(type, defaultAccount, filtersList) {
      const query = this.$route.query[type];
      let defaultFilter = defaultAccount;
      const filtersTenant = sessionStorage.getItem("filtersTenantTelnyx");
      if (filtersTenant) {
        const filters = JSON.parse(filtersTenant);
        defaultFilter = filters[type] || defaultAccount;
      }
      return multipleDefaultFilter(query, defaultFilter, filtersList);
    },

    setFilter(type, items) {
      const filtersTenant = sessionStorage.getItem("filtersTenantTelnyx");
      const options = filtersTenant ? JSON.parse(filtersTenant) : {};
      options[type] = items;
      sessionStorage.setItem("filtersTenantTelnyx", JSON.stringify(options));
    },
  },
}
</script>

<style>
.telnyx-messages-class .el-table th.el-table__cell > .cell {
  display: flex !important;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.telnyx-messages-class .el-table th:first-child.el-table__cell > .cell {
  padding-left: 18px;
}
</style>
