<template>
  <div class="text-center">
    <slot>
      <p class="text-center break-normal mb-4">
        <template v-if="number > 0">
          {{ computedDescription }}
        </template>
        <template v-else>
          {{ computedDescriptionNoData }}
        </template>
      </p>

      <template v-if="!hideFilters">
        <div v-if="statusChecked.length">
          <el-table
            :data="statusChecked"
            :default-sort="{ prop: 'filtersApplied', order: 'ascending' }"
            header-cell-class-name="table-header-cell"
            class="data-table"
          >
            <el-table-column label="Filters Applied" prop="filtersApplied">
              <template slot-scope="scope">
                <div v-if="typeof scope.row === 'string'">{{ scope.row }}</div>
                <div
                  v-if="
                    typeof scope.row === 'object' && scope.row.values.length !== 0
                  "
                >
                  <p>{{ scope.row.filter }}:</p>
                  <ul class="list-disc">
                    <li v-for="value in scope.row.values" class="text-xs ml-8">
                      {{ value }}
                    </li>
                  </ul>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <p v-else>No filters selected</p>
      </template>
    </slot>

    <div class="flex mt-3">
      <el-button
        v-if="modal"
        @click="closeDialog"
        :type="number > 0 ? 'default' : 'primary'"
        class="flex-1"
        size="small"
      >
        <template v-if="number > 0">Cancel</template>
        <template v-else>Go back</template>
      </el-button>
      <el-button
        v-if="number > 0"
        @click="exportAction"
        type="primary"
        class="flex-1"
        size="small"
      >
        Export
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "modal",
    "filterStatus",
    "number",
    "exportField",
    "exportFields",
    "statusChecked",
    "description",
    "descriptionNoData",
    "hideFilters",
    "selected"
  ],
  computed: {
    name() {
      return this.pluralize(this.number, this.exportField, this.exportFields);
    },
    computedDescription() {
      if (this.hideFilters) {
        return this.exportAllRecordsMessage;
      }
      return this.description ? this.description : this.exportSpecificRecordsMessage;
    },
    computedDescriptionNoData() {
      return this.descriptionNoData
        ? this.descriptionNoData
        : `Hera doesn't have any ${this.pluralize(
            this.number,
            this.exportField,
            this.exportFields
          )} to export`;
    },
    exportAllRecordsMessage() {
      const countRecords = this.selected ? this.number : 'all';
      return `Hera will export ${countRecords} records`;
    },
    exportSpecificRecordsMessage() {
      const pluralizedField = this.pluralize(this.number, this.exportField, this.exportFields);
      return `Hera will export ${this.number} ${pluralizedField}`;
    }
  },
  methods: {
    exportAction() {
      this.$emit("export-action");
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
