/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://5xfnq6tk2m.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "ForceLogoutAndRefresh",
            "endpoint": "https://6k6qeic9ej.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "ManageNotificationPreferences",
            "endpoint": "https://jvdijla4ck.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "ProcessPerformanceData",
            "endpoint": "https://624a8212d9.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "ScheduleNotifications",
            "endpoint": "https://v915ya19b7.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "SendInternalMessage",
            "endpoint": "https://ucm96qjjub.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "Signup",
            "endpoint": "https://nlz7ntwx2f.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "bandwidth",
            "endpoint": "https://zca0p2qp1e.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "csvDataExport",
            "endpoint": "https://kupmg3mk94.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "downloadFiles",
            "endpoint": "https://6b4shuhcbd.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "generateCounselingFilesApi",
            "endpoint": "https://cp604gx1c1.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "handleTelnyxResponse",
            "endpoint": "https://ch88v1y81d.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "heraPublicApi",
            "endpoint": "https://oz3nh4qwxb.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "intercomUpdateCompanies",
            "endpoint": "https://17fkuvaqrb.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "invoice",
            "endpoint": "https://mmcxsb0eh0.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "rds",
            "endpoint": "https://a3fm5ncsp4.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "s3downloadlink",
            "endpoint": "https://97g1s5sr5f.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "sendMessage",
            "endpoint": "https://yzfei91qul.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "sendMessages",
            "endpoint": "https://kuuprdh0rb.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "sendPendingCoachingMessage",
            "endpoint": "https://zu7d1s5mk2.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "stripeCharge",
            "endpoint": "https://z5oftvvyih.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "stripeSetup",
            "endpoint": "https://heq52hec88.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "tenant",
            "endpoint": "https://2pc6hmivf5.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "trackAuditLog",
            "endpoint": "https://5g1371j3jj.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "zoho",
            "endpoint": "https://aaw4k9fve8.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://doi2kddk5zfgxiqtav5npfqar4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:e08c0f55-179e-4a2f-9821-050e17fc9ee0",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Lt8dKaptb",
    "aws_user_pools_web_client_id": "1pr832o5u6s4jl1hsiuucoh0av",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hera2009fb1ba5504c8da9ba5f1fe34db738102235-production",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
