<template>
    <el-form id="tenant-form" data-cy="tenant-form" label-position="top" :rules="rules" :model="formFields" ref="form">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <el-collapse>
            <el-collapse-item title="Basic Info" name="1">
                <el-form-item label="Company Name" prop="companyName">
                    <el-input data-cy="company-name" v-model="formFields.companyName" :disabled="!$store.getters.isSystemAdmin"></el-input>
                </el-form-item>
                <div class="grid grid-cols-2 gap-2">
                <el-form-item label="Timezone" prop="timeZone">
                    <TimeZoneSelect data-cy="timezone" :tenantData="formFields" class=""/>
                </el-form-item>
                <el-form-item label="Short Code">
                    <el-input v-model="formFields.shortCode"></el-input>
                </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <el-form-item v-if="showCancelationNotes" label="Cancelation Reason">
                        <el-select v-model="formFields.accountCanceledReason" placeholder="Select a reason" class="w-full" clearable>
                        <el-option
                            v-for="(item, key) in getListCancelationReason"
                            :key="key"
                            :label="item"
                            :value="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="showCancelationNotes" label="Cancelation Notes">
                        <el-input rows="3" v-model="formFields.accountCanceledNotes"/>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <el-form-item v-if="isParentAccount" label="Allow Parent Data">
                        <el-checkbox v-model="formFields.allowParentData">
                        </el-checkbox>
                    </el-form-item>

                    <el-form-item v-else-if="isRegularAccount || isChildAccount" label="Parent Account">
                        <el-select
                            class="w-full"
                            clearable
                            filterable
                            placeholder="Select Parent Account"
                            v-model="formFields.parentAccountId">
                            <el-option
                                v-for="parentAccount in parentAccountList"
                                :key="parentAccount.id"
                                :label="parentAccount.companyName"
                                :value="parentAccount.id"
                                />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="!$store.getters.isZohoCrmDisabled" label="Zoho CRM Account ID" prop="zohoCrmAccountRecordId">
                        <el-input v-model="formFields.zohoCrmAccountRecordId" :disabled="!$store.getters.isSystemAdmin"></el-input>
                    </el-form-item>
                </div>
            </el-collapse-item>
            <el-collapse-item title="GrowSurf" name="2">
                <el-form-item label="GrowSurf Participant ID">
                    <el-input v-model="formFields.growSurfParticipantId" :disabled="formFields.growSurfParticipantReferralComplete"></el-input>
                </el-form-item>             
            </el-collapse-item>
            <el-collapse-item title="Settings" name="3">
                <el-form-item label="Service for messages" v-show="servicesMessage">
                    <el-select data-cy="service-type-message" v-model="formFields.messageServiceProvider" placeholder="Select service message">
                        <el-option
                            v-for="(item, key) in servicesMessage"
                            :key="key"
                            :label="item"
                            :value="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="Origination Number" v-show="formFields.originationNumber">
                    <el-input v-model="formFields.originationNumber" disabled></el-input>
                </el-form-item>
                <div class="grid grid-cols-2 gap-2">
                    <el-form-item label="Trial Expiration Date" prop="trialExpDate">
                        <el-date-picker 
                            data-cy="trial-exp-date"
                            type="date" 
                            placeholder="Select a day" 
                            format="MM/dd/yyyy" 
                            v-model="formFields.trialExpDate"
                            style="width:100%">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="First Interest" prop="firstInterestDateTime">
                        <DatePicker data-cy="first-interest-datetime" :selectedDate="formFields.firstInterestDateTime" placeholder="Select a date" @date-selected="handleDateSelected($event, 'firstInterestDateTime')" style="width:100%"/>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <el-form-item label="Customer Type" prop="customerType">
                        <el-select data-cy="customer-type" class="w-full" v-model="formFields.customerType" placeholder="Select a customer type" @change="changeCustomerType">
                            <el-option
                                v-for="(item, key) in getListCustomerType"
                                :key="key"
                                :label="item"
                                :value="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Customer Sub Type" prop="customerSubType">
                        <el-select data-cy="customer-subtype" class="w-full" v-model="formFields.customerSubType" placeholder="Select a customer sub type">
                            <el-option
                                v-for="(item, key) in listCustomerSubType"
                                :key="key"
                                :label="item"
                                :value="item"/>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <el-form-item label="Account Country" prop="accountCountry">
                        <el-select data-cy="account-country" class="w-full" v-model="formFields.accountCountry" placeholder="Select a account country">
                            <el-option
                                v-for="(item, key) in getListAccountCountry"
                                :key="key"
                                :label="item"
                                :value="item"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Temporary Account">
                        <el-switch data-cy="temporary-account" v-model="formFields.isTemporaryAccount" />
                    </el-form-item>
                </div>
                <el-form-item label="Testing Account">
                    <el-switch data-cy="testing-account" v-model="formFields.isTestingAccount" />
                </el-form-item>
            </el-collapse-item>
            <el-collapse-item title="Discounts" name="4">
                <el-form-item label="Discount %">
                    <el-input-number v-model.number="formFields.discountPercent" :min="0" :max="100" :step=".1"></el-input-number>
                </el-form-item>
                <el-form-item label="Discount % Label">
                    <el-input v-model="formFields.discountPercentLabel"></el-input>
                </el-form-item>
                <el-form-item label="Discount $">
                    <el-input-number v-model.number="formFields.discountFixed"></el-input-number>
                </el-form-item>
                <el-form-item label="Discount $ Label">
                    <el-input v-model="formFields.discountFixedLabel"></el-input>
                </el-form-item>
            </el-collapse-item>
            <el-collapse-item title="Notes" name="5">
                <el-form-item label="Notes:">
                    <el-input id="t-notes" type="textarea" :autosize="{ minRows: 4}" v-model="formFields.notes"></el-input>
                </el-form-item>                
            </el-collapse-item>
        </el-collapse>        
    </el-form>
</template>

<script>
import TimeZoneSelect from './../../../Settings/CompanyDetails/components/TimeZoneSelect.vue'
import { getTenantAccountData, tenantsByAccountType, updateTenantAccountType }  from '../queries';
import DatePicker from '@/components/Datepicker';

import {
    REGULAR_ACCOUNT,
    PARENT_ACCOUNT,
    CHILD_ACCOUNT,
    CUSTOMER_TYPE,
    CUSTOMER_SUBTYPE,
    ACCOUNT_COUNTRY,
    CUSTOMER_TYPE_SUBTYPE_AMAZON_DSP,
    CUSTOMER_TYPE_SUBTYPE_FEDEX_ISP,
    CUSTOMER_TYPE_AMAZON_DSP,
    ACCOUNT_CANCELED_REASON
} from '../constants';

export default {
    components:{ TimeZoneSelect, DatePicker},
    props:['formFields', 'servicesMessage'],
    data() {
        return {
            tenantData:{},
            prevTimeZone: '',
            listCustomerSubType: CUSTOMER_SUBTYPE,
            rules: {
                companyName: [
                    {required: true, message: "Please introduce a company name", trigger: 'blur'}
                ],
                timeZone: [
                    {required: true, message: "Please select a timezone", trigger: 'change'}
                ],
                trialExpDate: [
                    {required: true, message: "Please select a expiration date", trigger: 'change'}
                ],
                customerType: [
                    {required: true, message: "Please select a customer type", trigger: 'change'}
                ],
                customerSubType: [
                    {required: true, message: "Please select a customer sub type", trigger: 'change'}
                ],
                accountCountry: [
                    {required: true, message: "Please select a account country", trigger: 'change'}
                ],
                zohoCrmAccountRecordId: [
                    {required: true, message: "Please introduce a ZOHO account id", trigger: 'blur'}
                ],
            },
            parentAccountList: [],
        }
    },
    computed:{
        getListCancelationReason(){
            return ACCOUNT_CANCELED_REASON || []
        },
        getListCustomerType(){
            return CUSTOMER_TYPE || []
        },
        getListAccountCountry(){
            return ACCOUNT_COUNTRY || []
        },
        showCancelationNotes(){            
            return this.formFields.accountPremiumStatus?.includes('None') || this.formFields.customerStatus === 'Churned'
        },
        isRegularAccount() { // By default the account are treated as REGULAR
          return (
            !this.formFields.accountType ||
            this.formFields.accountType === REGULAR_ACCOUNT);
        },
        isParentAccount() {
          return this.formFields.accountType === PARENT_ACCOUNT;
        },
        isChildAccount() {
          return this.formFields.accountType === CHILD_ACCOUNT;
        },
    },
    methods: {
        handleDateSelected(date, model) {
            this.formFields[model] = date
        },
        async loadParentAccountList() {
            if (this.isParentAccount) return;

            const accountType = PARENT_ACCOUNT;
            this.parentAccountList = await this.gLoadListAll(tenantsByAccountType, { accountType }, 'tenantsByAccountType');
        },
        changeCustomerType(e){
            this.listCustomerSubType = (e === CUSTOMER_TYPE_AMAZON_DSP) ? CUSTOMER_TYPE_SUBTYPE_AMAZON_DSP : CUSTOMER_TYPE_SUBTYPE_FEDEX_ISP
        }
    },
    mounted() {
        this.loadParentAccountList();
    }
}
</script>
<style>
@media screen and (max-width: 768px) {
    .el-dialog[aria-label="Edit Tenant"]{
        width: 90% !important;
    }
    #edit-tenant .el-dialog__body{
        padding-top: 0;
    }
}

#tenant-form .el-collapse-item{
    padding:0;
    margin-bottom: 0 !important;
    border:0 !important;
    box-shadow: none !important;
}
#tenant-form .el-collapse-item div{
    border:0 !important;
    box-shadow: none !important;
}

</style>